import unit from 'fela-plugin-unit';
import namedKeys from 'fela-plugin-named-keys';
import monolithic from 'fela-monolithic';
import sortMediaQueryMobileFirst from 'fela-sort-media-query-mobile-first';

// const mediaQueries = ;

const namedKeysPlugin = namedKeys({
    min400px: '@media (min-width: 400px)',
    max400px: '@media (max-width: 399px)',
    min480px: '@media (min-width: 480px)',
    max480px: '@media (max-width: 479px)',
    min576px: '@media (min-width: 576px)',
    max576px: '@media (max-width: 575px)',
    min768px: '@media (min-width: 768px)',
    max768px: '@media (max-width: 767px)',
    min915px: '@media (min-width: 915px)',
    min992px: '@media (min-width: 992px)',
    max992px: '@media (max-width: 991px)',
    min1200px: '@media (min-width: 1200px)',
    max1200px: '@media (max-width: 1199px)',
    max1390px: '@media (max-width: 1389px)',
    min1390px: '@media (min-width: 1390px)',
    max1490px: '@media (max-width: 1489px)',
    min1490px: '@media (min-width: 1490px)',
    max1680px: '@media (max-width: 1679px)',
    min1680px: '@media (min-width: 1680px)',
    min1920px: '@media (min-width: 1920px)',
    max1920px: '@media (max-width: 1920px)',
});

const devMode = process.env.NODE_ENV !== 'production';
const plugins = [unit(), namedKeysPlugin];
const enhancers = [sortMediaQueryMobileFirst()];

if (devMode) {
    enhancers.push(
        monolithic({
            prettySelectors: true,
        }),
    );
}

export default {
    plugins,
    enhancers,
    devMode,
};
