// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conference-js": () => import("./../../../src/pages/conference.js" /* webpackChunkName: "component---src-pages-conference-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-festival-js": () => import("./../../../src/pages/festival.js" /* webpackChunkName: "component---src-pages-festival-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-remove-account-js": () => import("./../../../src/pages/removeAccount.js" /* webpackChunkName: "component---src-pages-remove-account-js" */)
}

